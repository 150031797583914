<div [formGroup]="form">
  <!-- TODO: Do we need -immediate-errors here? -->
  <div class="-immediate-errors"
       om-layout
       om-layout-gutter-large
       *ngIf="(measurementValidationRules$ | ngrxPush) as validationRules">

    <label class="-stacked"
           om-flex="15">
      sys
      <input omgInputNumber
             type="number"
             [min]="validationRules.systolicPressure.min"
             [max]="validationRules.systolicPressure.max"
             step="1"
             name="systolicPressure"
             omgSyncInput
             [control]="form.get('systolicPressure')"
             [ngClass]="{ 'show-error': !form.get('systolicPressure').value && form.get('diastolicPressure').value }" />
    </label>

    <label class="-stacked"
           om-flex="15">
      dia
      <input omgInputNumber
             type="number"
             [min]="validationRules.diastolicPressure.min"
             [max]="validationRules.diastolicPressure.max"
             step="1"
             name="diastolicPressure"
             omgSyncInput
             [control]="form.get('diastolicPressure')"
             [ngClass]="{ 'show-error': !form.get('diastolicPressure').value && form.get('systolicPressure').value }" />
    </label>

    <label class="-stacked"
           om-flex="15">
      HR
      <input omgInputNumber
             type="number"
             [min]="validationRules.heartRate.min"
             [max]="validationRules.heartRate.max"
             step="1"
             name="heartRate"
             omgSyncInput
             [control]="form.get('heartRate')" />
    </label>

    <label class="-stacked"
           om-flex="15">
      RR
      <input omgInputNumber
             type="number"
             [min]="validationRules.respiratoryRate.min"
             [max]="validationRules.respiratoryRate.max"
             step="1"
             name="respiratoryRate"
             omgSyncInput
             [control]="form.get('respiratoryRate')" />
    </label>

    <label class="-stacked"
           om-flex="15">
      temp
      <label om-layout
             om-layout-align="start center"
             om-layout-gutter-mini>
        <input omgInputNumber
               type="number"
               step="0.1"
               [min]="validationRules.temperature.min"
               [max]="validationRules.temperature.max"
               name="temperature"
               omgSyncInput
               [control]="form.get('temperature')"
               om-layout-fill />
        <span>°F</span>
      </label>
    </label>
  </div>

  <div class="-immediate-errors"
       om-layout
       om-layout-gutter-large>
    <label class="-stacked"
           om-flex="15">
      {{ weight.label }}
      <label class="plain-text"
             om-layout
             om-layout-align="center center"
             om-layout-gutter-mini>
        <input omgInputNumber
               type="number"
               step="0.1"
               min="2"
               max="999"
               [name]="weight.key"
               omgSyncInput
               [control]="form.get(weight.key)"
               om-layout-fill>
        <span>{{ weight.unit }}</span>
      </label>
    </label>

    <span class="padding-half-vertical"
          om-flex="15"
          om-layout
          om-layout-align="start end">
      <div *ngIf="(ordinalizedWeightPercentile$ | ngrxPush) as weightPercentile"
           om-layout
           om-layout-gutter-large>
        <span om-flex="15">| </span>
        <span>{{ weightPercentile }}</span>
      </div>

    </span>

    <label class="-stacked"
           om-flex="15">
      ht
      <label class="plain-text"
             om-layout
             om-layout-align="center center"
             om-layout-gutter-mini>
        <input omgInputNumber
               type="number"
               [min]="0"
               [max]="108"
               step="0.1"
               name="height"
               omgSyncInput
               [control]="form.get('height')"
               om-layout-fill>
        <span>in</span>
      </label>
    </label>

    <span class="padding-half-vertical"
          om-flex="15"
          om-layout
          om-layout-align="start end">
      <div *ngIf="(ordinalizedHeightPercentile$ | ngrxPush) as heightPercentile"
           om-layout
           om-layout-gutter-large>
        <span om-flex="15">| </span>
        <span>{{ heightPercentile }}</span>
      </div>
    </span>

    <label class="-stacked"
           om-flex="15"
           *ngIf="!isInfant">
      BMI
      <input class="om-input -readonly"
             type="number"
             name="bodyMassIndex"
             [value]="form.get('bodyMassIndex').value"
             omgSyncInput
             [control]="form.get('bodyMassIndex')"
             readonly>
    </label>

    <span class="padding-half-vertical"
          *ngIf="!isInfant"
          om-flex="15"
          om-layout
          om-layout-align="start end">
      <div *ngIf="(ordinalizedBmiPercentile$ | ngrxPush) as bmiPercentile"
           om-layout
           om-layout-gutter-large>
        <span om-flex="15">| </span>
        <span>{{ bmiPercentile }}</span>
      </div>
    </span>

    <label class="-stacked"
           *ngIf="isInfant"
           om-flex="15">
      head circ
      <label class="plain-text"
             om-layout
             om-layout-align="center center"
             om-layout-gutter-mini>
        <input omgInputNumber
               type="number"
               min="0"
               max="99.9"
               step="0.01"
               name="head"
               omgSyncInput
               [control]="form.get('headCircumference')"
               om-layout-fill>
        <span>cm</span>
      </label>
    </label>

    <span class="padding-half-vertical"
          *ngIf="isInfant"
          om-flex="15"
          om-layout
          om-layout-align="start end">
      <div *ngIf="(ordinalizedHeadCircumferencePercentile$ | ngrxPush) as headCircumferencePercentile"
           om-layout
           om-layout-gutter-large>
        <span om-flex="15">| </span>
        <span>{{ headCircumferencePercentile }}</span>
      </div>
    </span>
  </div>

  <div class="-immediate-errors"
       om-layout
       om-layout-gutter-large>

    <label class="-stacked"
           om-flex="15">
      SpO₂
      <label class="plain-text"
             om-layout
             om-layout-align="center center"
             om-layout-gutter-mini>
        <input omgInputNumber
               type="number"
               name="pulseOximetry"
               [min]="0"
               [max]="100"
               omgSyncInput
               [control]="form.get('pulseOximetry')"
               om-layout-fill>
        <span>%</span>
      </label>
    </label>

    <label class="-stacked"
           om-flex="15">
      FiO₂
      <label class="plain-text"
             om-layout
             om-layout-align="center center"
             om-layout-gutter-mini>
        <input omgInputNumber
               type="number"
               name="oxygenConcentration"
               [min]="0"
               [max]="100"
               omgSyncInput
               [control]="form.get('oxygenConcentration')"
               om-layout-fill>
        <span>%</span>
      </label>
    </label>
  </div>

  <div *ngIf="form.get('bodyMassIndex').invalid"
       class="om-messages">
    BMI must be in range 0-99
  </div>
</div>
