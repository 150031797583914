import { LMSParamsMap } from './lms-params.type';
import { zScoreFromMeasurement } from './z-score-to-percentile';

// Data comes from this file https://www.cdc.gov/growthcharts/data/zscore/bmiagerev.csv
const maleChildBMILMSParams: LMSParamsMap = {
  24: { lambda: -2.01118107, mu: 16.575027675, sigma: 0.080592465 },
  24.5: { lambda: -1.982373595, mu: 16.547774867, sigma: 0.0801274288 },
  25.5: { lambda: -1.924100169, mu: 16.494427632, sigma: 0.0792339937 },
  26.5: { lambda: -1.86549793, mu: 16.442595522, sigma: 0.0783893561 },
  27.5: { lambda: -1.807261899, mu: 16.392243398, sigma: 0.0775935012 },
  28.5: { lambda: -1.750118905, mu: 16.343336543, sigma: 0.0768464622 },
  29.5: { lambda: -1.69481584, mu: 16.295840971, sigma: 0.0761483079 },
  30.5: { lambda: -1.642106779, mu: 16.249723714, sigma: 0.0754991255 },
  31.5: { lambda: -1.592744414, mu: 16.204952678, sigma: 0.0748989935 },
  32.5: { lambda: -1.547442391, mu: 16.161498714, sigma: 0.0743479966 },
  33.5: { lambda: -1.506902601, mu: 16.119332582, sigma: 0.0738461386 },
  34.5: { lambda: -1.471770047, mu: 16.078427579, sigma: 0.0733933698 },
  35.5: { lambda: -1.442628957, mu: 16.038758958, sigma: 0.0729895508 },
  36.5: { lambda: -1.419991255, mu: 16.000304012, sigma: 0.0726344323 },
  37.5: { lambda: -1.404277619, mu: 15.963042771, sigma: 0.072327649 },
  38.5: { lambda: -1.39586317, mu: 15.926954175, sigma: 0.0720686401 },
  39.5: { lambda: -1.394935252, mu: 15.892025816, sigma: 0.0718568052 },
  40.5: { lambda: -1.401671596, mu: 15.858240929, sigma: 0.071691278 },
  41.5: { lambda: -1.416100312, mu: 15.825588223, sigma: 0.0715710933 },
  42.5: { lambda: -1.438164899, mu: 15.794057282, sigma: 0.0714951131 },
  43.5: { lambda: -1.467669032, mu: 15.763642549, sigma: 0.0714621062 },
  44.5: { lambda: -1.504376347, mu: 15.734336684, sigma: 0.0714706462 },
  45.5: { lambda: -1.547942838, mu: 15.706135657, sigma: 0.0715192177 },
  46.5: { lambda: -1.597896397, mu: 15.679040623, sigma: 0.0716062769 },
  47.5: { lambda: -1.653732283, mu: 15.653051916, sigma: 0.071730167 },
  48.5: { lambda: -1.714869347, mu: 15.628172692, sigma: 0.0718892136 },
  49.5: { lambda: -1.780673181, mu: 15.604407997, sigma: 0.0720817373 },
  50.5: { lambda: -1.850468473, mu: 15.58176458, sigma: 0.0723060813 },
  51.5: { lambda: -1.923551865, mu: 15.560250666, sigma: 0.0725606369 },
  52.5: { lambda: -1.999220429, mu: 15.539874597, sigma: 0.0728438397 },
  53.5: { lambda: -2.076707178, mu: 15.520649927, sigma: 0.0731543235 },
  54.5: { lambda: -2.155348017, mu: 15.502584267, sigma: 0.0734906668 },
  55.5: { lambda: -2.234438552, mu: 15.485689732, sigma: 0.0738516716 },
  56.5: { lambda: -2.313321723, mu: 15.469977177, sigma: 0.0742362346 },
  57.5: { lambda: -2.391381273, mu: 15.455456916, sigma: 0.0746433738 },
  58.5: { lambda: -2.468032491, mu: 15.442139608, sigma: 0.0750722636 },
  59.5: { lambda: -2.542781541, mu: 15.430032072, sigma: 0.0755221037 },
  60.5: { lambda: -2.61516595, mu: 15.419141631, sigma: 0.0759922501 },
  61.5: { lambda: -2.684789516, mu: 15.409473561, sigma: 0.0764821284 },
  62.5: { lambda: -2.751316949, mu: 15.401031388, sigma: 0.0769912324 },
  63.5: { lambda: -2.81445945, mu: 15.393817852, sigma: 0.0775191487 },
  64.5: { lambda: -2.87402476, mu: 15.387830936, sigma: 0.0780653898 },
  65.5: { lambda: -2.92984048, mu: 15.383069448, sigma: 0.0786295919 },
  66.5: { lambda: -2.981796828, mu: 15.37952958, sigma: 0.0792113694 },
  67.5: { lambda: -3.029831343, mu: 15.37720582, sigma: 0.0798103341 },
  68.5: { lambda: -3.073924224, mu: 15.376091068, sigma: 0.0804260861 },
  69.5: { lambda: -3.114093476, mu: 15.376176765, sigma: 0.0810582059 },
  70.5: { lambda: -3.15039004, mu: 15.37745304, sigma: 0.0817062489 },
  71.5: { lambda: -3.182893018, mu: 15.379908859, sigma: 0.0823697413 },
  72.5: { lambda: -3.21170511, mu: 15.383532169, sigma: 0.0830481778 },
  73.5: { lambda: -3.23694834, mu: 15.388310046, sigma: 0.0837410207 },
  74.5: { lambda: -3.25876011, mu: 15.39422883, sigma: 0.0844476998 },
  75.5: { lambda: -3.277281546, mu: 15.40127496, sigma: 0.0851676514 },
  76.5: { lambda: -3.292683774, mu: 15.40943252, sigma: 0.0859001836 },
  77.5: { lambda: -3.305124073, mu: 15.418686911, sigma: 0.0866446671 },
  78.5: { lambda: -3.314768951, mu: 15.429022732, sigma: 0.0874004212 },
  79.5: { lambda: -3.321785992, mu: 15.440424387, sigma: 0.0881667444 },
  80.5: { lambda: -3.326345795, mu: 15.452875806, sigma: 0.088942897 },
  81.5: { lambda: -3.328602731, mu: 15.466362178, sigma: 0.0897282019 },
  82.5: { lambda: -3.328725277, mu: 15.480867041, sigma: 0.0905218748 },
  83.5: { lambda: -3.32687018, mu: 15.496374654, sigma: 0.0913231621 },
  84.5: { lambda: -3.323188896, mu: 15.512869363, sigma: 0.0921313054 },
  85.5: { lambda: -3.317827016, mu: 15.530335632, sigma: 0.0929455443 },
  86.5: { lambda: -3.310923871, mu: 15.548758065, sigma: 0.0937651184 },
  87.5: { lambda: -3.302612272, mu: 15.568121426, sigma: 0.0945892702 },
  88.5: { lambda: -3.293018361, mu: 15.588410651, sigma: 0.0954172465 },
  89.5: { lambda: -3.282260813, mu: 15.60961101, sigma: 0.0962483005 },
  90.5: { lambda: -3.270454609, mu: 15.63170735, sigma: 0.0970816941 },
  91.5: { lambda: -3.257703616, mu: 15.654685628, sigma: 0.0979166976 },
  92.5: { lambda: -3.244108214, mu: 15.678531387, sigma: 0.0987525931 },
  93.5: { lambda: -3.229761713, mu: 15.703230518, sigma: 0.0995886749 },
  94.5: { lambda: -3.214751287, mu: 15.728769113, sigma: 0.1004242507 },
  95.5: { lambda: -3.199158184, mu: 15.755133465, sigma: 0.1012586429 },
  96.5: { lambda: -3.18305795, mu: 15.782310065, sigma: 0.1020911894 },
  97.5: { lambda: -3.166520664, mu: 15.810285603, sigma: 0.1029212448 },
  98.5: { lambda: -3.1496103, mu: 15.839047084, sigma: 0.1037481885 },
  99.5: { lambda: -3.132389637, mu: 15.868581229, sigma: 0.1045713862 },
  100.5: { lambda: -3.114911153, mu: 15.898875618, sigma: 0.1053902685 },
  101.5: { lambda: -3.097226399, mu: 15.929917651, sigma: 0.1062042575 },
  102.5: { lambda: -3.079383079, mu: 15.961694805, sigma: 0.1070127883 },
  103.5: { lambda: -3.061423765, mu: 15.994194894, sigma: 0.1078153274 },
  104.5: { lambda: -3.043386071, mu: 16.027406071, sigma: 0.1086113736 },
  105.5: { lambda: -3.025310003, mu: 16.061315897, sigma: 0.1094003876 },
  106.5: { lambda: -3.007225737, mu: 16.095912922, sigma: 0.1101819146 },
  107.5: { lambda: -2.989164598, mu: 16.131185315, sigma: 0.1109554781 },
  108.5: { lambda: -2.971148225, mu: 16.167122344, sigma: 0.1117206908 },
  109.5: { lambda: -2.953208047, mu: 16.203711677, sigma: 0.1124770587 },
  110.5: { lambda: -2.935363951, mu: 16.240942388, sigma: 0.1132241995 },
  111.5: { lambda: -2.917635157, mu: 16.278803458, sigma: 0.1139617339 },
  112.5: { lambda: -2.900039803, mu: 16.317283847, sigma: 0.1146892914 },
  113.5: { lambda: -2.882593796, mu: 16.356372672, sigma: 0.1154065227 },
  114.5: { lambda: -2.865311266, mu: 16.396059161, sigma: 0.1161130971 },
  115.5: { lambda: -2.848204697, mu: 16.436332645, sigma: 0.1168087018 },
  116.5: { lambda: -2.831285052, mu: 16.477182556, sigma: 0.1174930418 },
  117.5: { lambda: -2.81456189, mu: 16.518598425, sigma: 0.1181658396 },
  118.5: { lambda: -2.79804347, mu: 16.560569873, sigma: 0.1188268351 },
  119.5: { lambda: -2.781736856, mu: 16.603086612, sigma: 0.1194757852 },
  120.5: { lambda: -2.765648008, mu: 16.646138439, sigma: 0.1201124636 },
  121.5: { lambda: -2.749782197, mu: 16.689715178, sigma: 0.1207366562 },
  122.5: { lambda: -2.734142443, mu: 16.733806953, sigma: 0.1213481813 },
  123.5: { lambda: -2.718732873, mu: 16.778403632, sigma: 0.121946849 },
  124.5: { lambda: -2.703555506, mu: 16.82349538, sigma: 0.1225325012 },
  125.5: { lambda: -2.688611957, mu: 16.869072375, sigma: 0.1231049908 },
  126.5: { lambda: -2.673903164, mu: 16.915124866, sigma: 0.1236641859 },
  127.5: { lambda: -2.659429443, mu: 16.961643168, sigma: 0.1242099694 },
  128.5: { lambda: -2.645190534, mu: 17.00861766, sigma: 0.1247422387 },
  129.5: { lambda: -2.631185649, mu: 17.056038787, sigma: 0.1252609054 },
  130.5: { lambda: -2.617413511, mu: 17.103897052, sigma: 0.125765895 },
  131.5: { lambda: -2.603872392, mu: 17.152183022, sigma: 0.1262571467 },
  132.5: { lambda: -2.590560148, mu: 17.200887318, sigma: 0.1267346133 },
  133.5: { lambda: -2.577474253, mu: 17.250000623, sigma: 0.1271982604 },
  134.5: { lambda: -2.564611831, mu: 17.299513673, sigma: 0.1276480666 },
  135.5: { lambda: -2.551969684, mu: 17.349417258, sigma: 0.128084023 },
  136.5: { lambda: -2.539539972, mu: 17.399703081, sigma: 0.1285061919 },
  137.5: { lambda: -2.527325681, mu: 17.450360715, sigma: 0.1289144974 },
  138.5: { lambda: -2.515320235, mu: 17.501381606, sigma: 0.1293090012 },
  139.5: { lambda: -2.503519447, mu: 17.552756739, sigma: 0.1296897408 },
  140.5: { lambda: -2.491918934, mu: 17.604477144, sigma: 0.1300567649 },
  141.5: { lambda: -2.480514136, mu: 17.656533895, sigma: 0.1304101325 },
  142.5: { lambda: -2.469300331, mu: 17.708918107, sigma: 0.1307499132 },
  143.5: { lambda: -2.458272656, mu: 17.761620938, sigma: 0.1310761867 },
  144.5: { lambda: -2.447426113, mu: 17.814633586, sigma: 0.1313890423 },
  145.5: { lambda: -2.436755595, mu: 17.867947289, sigma: 0.1316885791 },
  146.5: { lambda: -2.426255887, mu: 17.92155332, sigma: 0.1319749052 },
  147.5: { lambda: -2.415921689, mu: 17.975442992, sigma: 0.1322481377 },
  148.5: { lambda: -2.405747619, mu: 18.029607653, sigma: 0.1325084026 },
  149.5: { lambda: -2.395728233, mu: 18.084038684, sigma: 0.1327558343 },
  150.5: { lambda: -2.385858029, mu: 18.138727501, sigma: 0.1329905752 },
  151.5: { lambda: -2.376131459, mu: 18.193665552, sigma: 0.133212776 },
  152.5: { lambda: -2.366542942, mu: 18.248844314, sigma: 0.1334225948 },
  153.5: { lambda: -2.357086871, mu: 18.304255296, sigma: 0.1336201973 },
  154.5: { lambda: -2.347757625, mu: 18.359890034, sigma: 0.1338057563 },
  155.5: { lambda: -2.338549576, mu: 18.415740092, sigma: 0.1339794518 },
  156.5: { lambda: -2.3294571, mu: 18.471797059, sigma: 0.1341414703 },
  157.5: { lambda: -2.320474586, mu: 18.528052549, sigma: 0.1342920051 },
  158.5: { lambda: -2.311596446, mu: 18.584498199, sigma: 0.1344312555 },
  159.5: { lambda: -2.302817124, mu: 18.641125665, sigma: 0.134559427 },
  160.5: { lambda: -2.294131107, mu: 18.697926627, sigma: 0.1346767311 },
  161.5: { lambda: -2.285532933, mu: 18.754892781, sigma: 0.1347833849 },
  162.5: { lambda: -2.277017201, mu: 18.812015839, sigma: 0.1348796107 },
  163.5: { lambda: -2.268578584, mu: 18.869287528, sigma: 0.1349656365 },
  164.5: { lambda: -2.260211837, mu: 18.92669959, sigma: 0.1350416951 },
  165.5: { lambda: -2.251911809, mu: 18.984243775, sigma: 0.1351080243 },
  166.5: { lambda: -2.243673453, mu: 19.041911845, sigma: 0.1351648666 },
  167.5: { lambda: -2.235491842, mu: 19.099695568, sigma: 0.1352124688 },
  168.5: { lambda: -2.227362173, mu: 19.157586716, sigma: 0.1352510825 },
  169.5: { lambda: -2.21927979, mu: 19.215577065, sigma: 0.1352809633 },
  170.5: { lambda: -2.211240187, mu: 19.27365839, sigma: 0.1353023707 },
  171.5: { lambda: -2.203239029, mu: 19.331822466, sigma: 0.1353155684 },
  172.5: { lambda: -2.195272161, mu: 19.390061061, sigma: 0.1353208237 },
  173.5: { lambda: -2.187335625, mu: 19.448365938, sigma: 0.1353184074 },
  174.5: { lambda: -2.179425674, mu: 19.506728848, sigma: 0.1353085942 },
  175.5: { lambda: -2.171538789, mu: 19.565141532, sigma: 0.1352916617 },
  176.5: { lambda: -2.163671689, mu: 19.623595714, sigma: 0.1352678911 },
  177.5: { lambda: -2.155821357, mu: 19.682083101, sigma: 0.1352375667 },
  178.5: { lambda: -2.147985046, mu: 19.740595376, sigma: 0.1352009759 },
  179.5: { lambda: -2.140160305, mu: 19.799124201, sigma: 0.1351584088 },
  180.5: { lambda: -2.132344989, mu: 19.857661209, sigma: 0.1351101588 },
  181.5: { lambda: -2.124537282, mu: 19.916198004, sigma: 0.1350565219 },
  182.5: { lambda: -2.116735712, mu: 19.974726154, sigma: 0.1349977968 },
  183.5: { lambda: -2.108939167, mu: 20.033237194, sigma: 0.134934285 },
  184.5: { lambda: -2.10114692, mu: 20.091722615, sigma: 0.1348662908 },
  185.5: { lambda: -2.093358637, mu: 20.15017387, sigma: 0.1347941208 },
  186.5: { lambda: -2.085574403, mu: 20.208582361, sigma: 0.1347180845 },
  187.5: { lambda: -2.077794735, mu: 20.266939444, sigma: 0.1346384938 },
  188.5: { lambda: -2.070020599, mu: 20.325236424, sigma: 0.1345556632 },
  189.5: { lambda: -2.062253431, mu: 20.383464548, sigma: 0.1344699098 },
  190.5: { lambda: -2.054495145, mu: 20.441615008, sigma: 0.1343815533 },
  191.5: { lambda: -2.046748156, mu: 20.499678935, sigma: 0.1342909159 },
  192.5: { lambda: -2.039015385, mu: 20.557647399, sigma: 0.1341983225 },
  193.5: { lambda: -2.031300282, mu: 20.615511404, sigma: 0.1341041006 },
  194.5: { lambda: -2.023606828, mu: 20.673261889, sigma: 0.1340085806 },
  195.5: { lambda: -2.015942013, mu: 20.730889051, sigma: 0.1339120657 },
  196.5: { lambda: -2.008305745, mu: 20.788385102, sigma: 0.1338149542 },
  197.5: { lambda: -2.000706389, mu: 20.845740029, sigma: 0.1337175518 },
  198.5: { lambda: -1.993150137, mu: 20.902944494, sigma: 0.1336202002 },
  199.5: { lambda: -1.985643741, mu: 20.959989088, sigma: 0.1335232441 },
  200.5: { lambda: -1.97819451, mu: 21.01686433, sigma: 0.1334270316 },
  201.5: { lambda: -1.970810308, mu: 21.073560674, sigma: 0.1333319137 },
  202.5: { lambda: -1.96349954, mu: 21.130068501, sigma: 0.1332382449 },
  203.5: { lambda: -1.956271141, mu: 21.186378131, sigma: 0.1331463832 },
  204.5: { lambda: -1.949134561, mu: 21.242479819, sigma: 0.1330566901 },
  205.5: { lambda: -1.942099744, mu: 21.298363759, sigma: 0.1329695305 },
  206.5: { lambda: -1.935177101, mu: 21.354020094, sigma: 0.1328852735 },
  207.5: { lambda: -1.92837748, mu: 21.409438911, sigma: 0.1328042916 },
  208.5: { lambda: -1.921712136, mu: 21.464610257, sigma: 0.1327269615 },
  209.5: { lambda: -1.915192685, mu: 21.519524136, sigma: 0.1326536641 },
  210.5: { lambda: -1.908831065, mu: 21.574170525, sigma: 0.1325847841 },
  211.5: { lambda: -1.902639482, mu: 21.628539373, sigma: 0.1325207109 },
  212.5: { lambda: -1.896630358, mu: 21.682620618, sigma: 0.1324618378 },
  213.5: { lambda: -1.890816268, mu: 21.736404191, sigma: 0.1324085629 },
  214.5: { lambda: -1.885209876, mu: 21.789880029, sigma: 0.1323612888 },
  215.5: { lambda: -1.879823505, mu: 21.843038191, sigma: 0.1323204265 },
  216.5: { lambda: -1.874670324, mu: 21.895868501, sigma: 0.1322863818 },
  217.5: { lambda: -1.869760299, mu: 21.948361684, sigma: 0.1322595999 },
  218.5: { lambda: -1.865113245, mu: 22.00050569, sigma: 0.1322404176 },
  219.5: { lambda: -1.860734944, mu: 22.052292423, sigma: 0.1322293301 },
  220.5: { lambda: -1.85663384, mu: 22.103713048, sigma: 0.132226801 },
  221.5: { lambda: -1.852827186, mu: 22.154756029, sigma: 0.1322332005 },
  222.5: { lambda: -1.849323204, mu: 22.205412485, sigma: 0.1322489931 },
  223.5: { lambda: -1.846131607, mu: 22.255673, sigma: 0.1322746254 },
  224.5: { lambda: -1.843261294, mu: 22.305528306, sigma: 0.132310549 },
  225.5: { lambda: -1.840720248, mu: 22.354969299, sigma: 0.1323572208 },
  226.5: { lambda: -1.83851544, mu: 22.403987057, sigma: 0.132415103 },
  227.5: { lambda: -1.83665586, mu: 22.452571818, sigma: 0.132484631 },
  228.5: { lambda: -1.835138046, mu: 22.500717781, sigma: 0.1325663592 },
  229.5: { lambda: -1.833972004, mu: 22.548414372, sigma: 0.132660699 },
  230.5: { lambda: -1.833157751, mu: 22.595654215, sigma: 0.1327681527 },
  231.5: { lambda: -1.83269562, mu: 22.642429557, sigma: 0.1328892105 },
  232.5: { lambda: -1.832584342, mu: 22.688732921, sigma: 0.1330243684 },
  233.5: { lambda: -1.832820974, mu: 22.734557126, sigma: 0.1331741285 },
  234.5: { lambda: -1.833400825, mu: 22.779895295, sigma: 0.1333389994 },
  235.5: { lambda: -1.834317405, mu: 22.824740868, sigma: 0.1335194959 },
  236.5: { lambda: -1.83555752, mu: 22.869089116, sigma: 0.1337161923 },
  237.5: { lambda: -1.837119466, mu: 22.912931508, sigma: 0.1339295249 },
  238.5: { lambda: -1.838987063, mu: 22.956263733, sigma: 0.1341600729 },
  239.5: { lambda: -1.841146139, mu: 22.999080616, sigma: 0.1344083809 },
  240: { lambda: -1.84233016, mu: 23.020294238, sigma: 0.134539365 },
  240.5: { lambda: -1.843580575, mu: 23.041377338, sigma: 0.1346750014 },
};

const femaleChildBMILMSParams: LMSParamsMap = {
  24: { lambda: -0.98660853, mu: 16.423396643, sigma: 0.085451785 },
  24.5: { lambda: -1.024496827, mu: 16.388040561, sigma: 0.085025838 },
  25.5: { lambda: -1.102698353, mu: 16.318971901, sigma: 0.0842140522 },
  26.5: { lambda: -1.18396635, mu: 16.252079845, sigma: 0.083455124 },
  27.5: { lambda: -1.268071036, mu: 16.187346686, sigma: 0.0827482842 },
  28.5: { lambda: -1.354751525, mu: 16.124754481, sigma: 0.0820927371 },
  29.5: { lambda: -1.443689692, mu: 16.064287623, sigma: 0.0814877172 },
  30.5: { lambda: -1.53454192, mu: 16.005930007, sigma: 0.0809324482 },
  31.5: { lambda: -1.626928093, mu: 15.94966631, sigma: 0.0804261754 },
  32.5: { lambda: -1.720434829, mu: 15.895481969, sigma: 0.0799681758 },
  33.5: { lambda: -1.814635262, mu: 15.843361791, sigma: 0.0795577348 },
  34.5: { lambda: -1.909076262, mu: 15.793291456, sigma: 0.0791941867 },
  35.5: { lambda: -2.003296102, mu: 15.7452564, sigma: 0.0788768946 },
  36.5: { lambda: -2.096828937, mu: 15.699241878, sigma: 0.0786052551 },
  37.5: { lambda: -2.189211877, mu: 15.655232823, sigma: 0.0783786964 },
  38.5: { lambda: -2.279991982, mu: 15.613213709, sigma: 0.0781966743 },
  39.5: { lambda: -2.368732949, mu: 15.573168427, sigma: 0.078058667 },
  40.5: { lambda: -2.455021314, mu: 15.53508019, sigma: 0.077964169 },
  41.5: { lambda: -2.538471972, mu: 15.498931449, sigma: 0.0779126837 },
  42.5: { lambda: -2.618732901, mu: 15.464703844, sigma: 0.0779037156 },
  43.5: { lambda: -2.695488973, mu: 15.432378168, sigma: 0.0779367628 },
  44.5: { lambda: -2.768464816, mu: 15.401934364, sigma: 0.078011309 },
  45.5: { lambda: -2.837426693, mu: 15.373351541, sigma: 0.0781268172 },
  46.5: { lambda: -2.902178205, mu: 15.346608415, sigma: 0.0782827393 },
  47.5: { lambda: -2.962580386, mu: 15.321681814, sigma: 0.0784784485 },
  48.5: { lambda: -3.018521987, mu: 15.298548972, sigma: 0.0787133246 },
  49.5: { lambda: -3.069936555, mu: 15.277186179, sigma: 0.0789866938 },
  50.5: { lambda: -3.116795864, mu: 15.257569204, sigma: 0.0792978405 },
  51.5: { lambda: -3.159107331, mu: 15.239673384, sigma: 0.079646006 },
  52.5: { lambda: -3.196911083, mu: 15.22347371, sigma: 0.0800303887 },
  53.5: { lambda: -3.230276759, mu: 15.208944907, sigma: 0.0804501449 },
  54.5: { lambda: -3.259300182, mu: 15.19606152, sigma: 0.0809043905 },
  55.5: { lambda: -3.284099963, mu: 15.184797987, sigma: 0.0813922027 },
  56.5: { lambda: -3.30481415, mu: 15.175128708, sigma: 0.0819126232 },
  57.5: { lambda: -3.321596954, mu: 15.167028107, sigma: 0.0824646608 },
  58.5: { lambda: -3.334615646, mu: 15.160470684, sigma: 0.0830472946 },
  59.5: { lambda: -3.344047622, mu: 15.155431067, sigma: 0.0836594775 },
  60.5: { lambda: -3.35007771, mu: 15.15188405, sigma: 0.0843001394 },
  61.5: { lambda: -3.352893805, mu: 15.149804788, sigma: 0.0849681996 },
  62.5: { lambda: -3.352691376, mu: 15.14916825, sigma: 0.085662539 },
  63.5: { lambda: -3.34966438, mu: 15.149949835, sigma: 0.086382035 },
  64.5: { lambda: -3.343998803, mu: 15.152125852, sigma: 0.0871255909 },
  65.5: { lambda: -3.335889574, mu: 15.155671862, sigma: 0.0878920466 },
  66.5: { lambda: -3.325522491, mu: 15.160564192, sigma: 0.0886802643 },
  67.5: { lambda: -3.31307846, mu: 15.166779473, sigma: 0.0894891056 },
  68.5: { lambda: -3.298732648, mu: 15.174294641, sigma: 0.090317434 },
  69.5: { lambda: -3.282653831, mu: 15.183086936, sigma: 0.0911641168 },
  70.5: { lambda: -3.265003896, mu: 15.193133896, sigma: 0.0920280276 },
  71.5: { lambda: -3.245937506, mu: 15.204413348, sigma: 0.0929080476 },
  72.5: { lambda: -3.225606516, mu: 15.216902957, sigma: 0.0938030328 },
  73.5: { lambda: -3.204146115, mu: 15.230581504, sigma: 0.0947119161 },
  74.5: { lambda: -3.181690237, mu: 15.245427448, sigma: 0.0956335947 },
  75.5: { lambda: -3.158363475, mu: 15.261419664, sigma: 0.096566992 },
  76.5: { lambda: -3.134282833, mu: 15.278537278, sigma: 0.0975110459 },
  77.5: { lambda: -3.109557879, mu: 15.296759667, sigma: 0.0984647101 },
  78.5: { lambda: -3.084290931, mu: 15.316066442, sigma: 0.0994269552 },
  79.5: { lambda: -3.058577292, mu: 15.336437447, sigma: 0.1003967693 },
  80.5: { lambda: -3.032505499, mu: 15.357852744, sigma: 0.1013731591 },
  81.5: { lambda: -3.0061576, mu: 15.380292613, sigma: 0.1023551503 },
  82.5: { lambda: -2.979609448, mu: 15.403737535, sigma: 0.1033417884 },
  83.5: { lambda: -2.952930993, mu: 15.428168191, sigma: 0.1043321392 },
  84.5: { lambda: -2.926186592, mu: 15.453565452, sigma: 0.1053252892 },
  85.5: { lambda: -2.899435307, mu: 15.479910374, sigma: 0.1063203463 },
  86.5: { lambda: -2.872731211, mu: 15.507184187, sigma: 0.1073164399 },
  87.5: { lambda: -2.846123683, mu: 15.535368293, sigma: 0.1083127212 },
  88.5: { lambda: -2.819657704, mu: 15.564444257, sigma: 0.1093083637 },
  89.5: { lambda: -2.793374145, mu: 15.594393802, sigma: 0.1103025629 },
  90.5: { lambda: -2.767310047, mu: 15.625198798, sigma: 0.111294537 },
  91.5: { lambda: -2.741498897, mu: 15.656841259, sigma: 0.1122835261 },
  92.5: { lambda: -2.715970894, mu: 15.689303334, sigma: 0.113268793 },
  93.5: { lambda: -2.690753197, mu: 15.722567299, sigma: 0.1142496222 },
  94.5: { lambda: -2.665870146, mu: 15.756615553, sigma: 0.1152253207 },
  95.5: { lambda: -2.641343436, mu: 15.791430622, sigma: 0.1161952181 },
  96.5: { lambda: -2.617192204, mu: 15.826995169, sigma: 0.1171586674 },
  97.5: { lambda: -2.593430614, mu: 15.863292407, sigma: 0.1181150731 },
  98.5: { lambda: -2.570076037, mu: 15.900304841, sigma: 0.1190638073 },
  99.5: { lambda: -2.547141473, mu: 15.938015446, sigma: 0.1200042898 },
  100.5: { lambda: -2.524635245, mu: 15.976407871, sigma: 0.1209359936 },
  101.5: { lambda: -2.502569666, mu: 16.015464834, sigma: 0.1218583548 },
  102.5: { lambda: -2.48095189, mu: 16.055169844, sigma: 0.1227708703 },
  103.5: { lambda: -2.459785573, mu: 16.09550688, sigma: 0.1236730846 },
  104.5: { lambda: -2.439080117, mu: 16.136458809, sigma: 0.1245644841 },
  105.5: { lambda: -2.418838304, mu: 16.178009551, sigma: 0.125444639 },
  106.5: { lambda: -2.399063683, mu: 16.220142813, sigma: 0.1263131206 },
  107.5: { lambda: -2.379756861, mu: 16.262842771, sigma: 0.1271695453 },
  108.5: { lambda: -2.360920527, mu: 16.306093162, sigma: 0.1280135154 },
  109.5: { lambda: -2.342557728, mu: 16.349877586, sigma: 0.1288446388 },
  110.5: { lambda: -2.324663326, mu: 16.39418118, sigma: 0.1296626372 },
  111.5: { lambda: -2.307240716, mu: 16.438987413, sigma: 0.1304671382 },
  112.5: { lambda: -2.290287663, mu: 16.484280823, sigma: 0.1312578524 },
  113.5: { lambda: -2.273803847, mu: 16.530045538, sigma: 0.1320344789 },
  114.5: { lambda: -2.257782149, mu: 16.57626713, sigma: 0.132796819 },
  115.5: { lambda: -2.242227723, mu: 16.62292864, sigma: 0.1335445247 },
  116.5: { lambda: -2.227132805, mu: 16.670015716, sigma: 0.1342774356 },
  117.5: { lambda: -2.212495585, mu: 16.717512877, sigma: 0.1349953236 },
  118.5: { lambda: -2.19831275, mu: 16.765404961, sigma: 0.1356979956 },
  119.5: { lambda: -2.184580762, mu: 16.813676886, sigma: 0.1363852755 },
  120.5: { lambda: -2.171295888, mu: 16.862313656, sigma: 0.1370570042 },
  121.5: { lambda: -2.158454232, mu: 16.911300357, sigma: 0.1377130391 },
  122.5: { lambda: -2.146051754, mu: 16.960622156, sigma: 0.1383532537 },
  123.5: { lambda: -2.134084303, mu: 17.010264304, sigma: 0.1389775374 },
  124.5: { lambda: -2.122547629, mu: 17.060212133, sigma: 0.1395857952 },
  125.5: { lambda: -2.111437411, mu: 17.110451055, sigma: 0.1401779469 },
  126.5: { lambda: -2.100749266, mu: 17.160966564, sigma: 0.1407539274 },
  127.5: { lambda: -2.090478774, mu: 17.211744236, sigma: 0.1413136859 },
  128.5: { lambda: -2.080621484, mu: 17.262769728, sigma: 0.1418571858 },
  129.5: { lambda: -2.071172932, mu: 17.314028776, sigma: 0.1423844043 },
  130.5: { lambda: -2.062128649, mu: 17.365507199, sigma: 0.1428953318 },
  131.5: { lambda: -2.053484173, mu: 17.417190895, sigma: 0.143389972 },
  132.5: { lambda: -2.045235058, mu: 17.469065845, sigma: 0.1438683412 },
  133.5: { lambda: -2.03737688, mu: 17.52111811, sigma: 0.1443304685 },
  134.5: { lambda: -2.029906684, mu: 17.573333469, sigma: 0.1447763715 },
  135.5: { lambda: -2.022817914, mu: 17.625698688, sigma: 0.1452061381 },
  136.5: { lambda: -2.016107084, mu: 17.678199868, sigma: 0.1456198193 },
  137.5: { lambda: -2.009769905, mu: 17.730823397, sigma: 0.1460174906 },
  138.5: { lambda: -2.003802134, mu: 17.783555746, sigma: 0.1463992386 },
  139.5: { lambda: -1.998199572, mu: 17.836383471, sigma: 0.1467651605 },
  140.5: { lambda: -1.992958064, mu: 17.889293209, sigma: 0.1471153639 },
  141.5: { lambda: -1.988073505, mu: 17.942271684, sigma: 0.1474499668 },
  142.5: { lambda: -1.983541835, mu: 17.995305704, sigma: 0.1477690965 },
  143.5: { lambda: -1.979359041, mu: 18.048382162, sigma: 0.1480728906 },
  144.5: { lambda: -1.975521156, mu: 18.101488036, sigma: 0.1483614954 },
  145.5: { lambda: -1.972024258, mu: 18.154610394, sigma: 0.1486350668 },
  146.5: { lambda: -1.968864465, mu: 18.207736386, sigma: 0.1488937694 },
  147.5: { lambda: -1.966037938, mu: 18.260853253, sigma: 0.1491377764 },
  148.5: { lambda: -1.963540872, mu: 18.313948324, sigma: 0.1493672695 },
  149.5: { lambda: -1.961369499, mu: 18.367009017, sigma: 0.1495824386 },
  150.5: { lambda: -1.959520079, mu: 18.420022839, sigma: 0.1497834816 },
  151.5: { lambda: -1.9579889, mu: 18.472977388, sigma: 0.1499706043 },
  152.5: { lambda: -1.956772271, mu: 18.525860352, sigma: 0.1501440201 },
  153.5: { lambda: -1.95586652, mu: 18.578659513, sigma: 0.1503039498 },
  154.5: { lambda: -1.955267984, mu: 18.631362745, sigma: 0.1504506214 },
  155.5: { lambda: -1.954973011, mu: 18.683958013, sigma: 0.1505842702 },
  156.5: { lambda: -1.954977947, mu: 18.736433381, sigma: 0.1507051384 },
  157.5: { lambda: -1.955279136, mu: 18.788777004, sigma: 0.1508134748 },
  158.5: { lambda: -1.955872909, mu: 18.840977134, sigma: 0.1509095352 },
  159.5: { lambda: -1.956755579, mu: 18.893022121, sigma: 0.1509935818 },
  160.5: { lambda: -1.957923436, mu: 18.944900411, sigma: 0.1510658829 },
  161.5: { lambda: -1.959372737, mu: 18.996600549, sigma: 0.1511267136 },
  162.5: { lambda: -1.9610997, mu: 19.048111179, sigma: 0.1511763547 },
  163.5: { lambda: -1.963100496, mu: 19.099421046, sigma: 0.1512150935 },
  164.5: { lambda: -1.96537124, mu: 19.150518994, sigma: 0.1512432229 },
  165.5: { lambda: -1.967907983, mu: 19.201393971, sigma: 0.1512610419 },
  166.5: { lambda: -1.970706706, mu: 19.252035026, sigma: 0.1512688553 },
  167.5: { lambda: -1.973763307, mu: 19.302431312, sigma: 0.1512669735 },
  168.5: { lambda: -1.977073595, mu: 19.352572085, sigma: 0.1512557127 },
  169.5: { lambda: -1.980633277, mu: 19.402446707, sigma: 0.1512353947 },
  170.5: { lambda: -1.984437954, mu: 19.452044646, sigma: 0.1512063468 },
  171.5: { lambda: -1.988483106, mu: 19.501355476, sigma: 0.1511689019 },
  172.5: { lambda: -1.992764085, mu: 19.550368876, sigma: 0.1511233983 },
  173.5: { lambda: -1.997276103, mu: 19.599074637, sigma: 0.1510701797 },
  174.5: { lambda: -2.002014224, mu: 19.647462655, sigma: 0.1510095954 },
  175.5: { lambda: -2.00697335, mu: 19.695522937, sigma: 0.1509419999 },
  176.5: { lambda: -2.012148213, mu: 19.743245597, sigma: 0.1508677534 },
  177.5: { lambda: -2.017533363, mu: 19.790620862, sigma: 0.1507872211 },
  178.5: { lambda: -2.023123159, mu: 19.837639068, sigma: 0.1507007738 },
  179.5: { lambda: -2.028911755, mu: 19.884290662, sigma: 0.1506087878 },
  180.5: { lambda: -2.034893091, mu: 19.930566203, sigma: 0.1505116446 },
  181.5: { lambda: -2.041060881, mu: 19.976456361, sigma: 0.1504097312 },
  182.5: { lambda: -2.047408604, mu: 20.021951917, sigma: 0.1503034402 },
  183.5: { lambda: -2.05392949, mu: 20.067043765, sigma: 0.1501931693 },
  184.5: { lambda: -2.060616513, mu: 20.11172291, sigma: 0.1500793222 },
  185.5: { lambda: -2.067462375, mu: 20.155980469, sigma: 0.1499623077 },
  186.5: { lambda: -2.074459502, mu: 20.199807672, sigma: 0.1498425404 },
  187.5: { lambda: -2.081600029, mu: 20.243195857, sigma: 0.1497204407 },
  188.5: { lambda: -2.088875793, mu: 20.286136477, sigma: 0.1495964342 },
  189.5: { lambda: -2.096278323, mu: 20.328621093, sigma: 0.1494709526 },
  190.5: { lambda: -2.103798828, mu: 20.370641376, sigma: 0.1493444333 },
  191.5: { lambda: -2.111428194, mu: 20.412189106, sigma: 0.1492173194 },
  192.5: { lambda: -2.119156972, mu: 20.453256172, sigma: 0.14909006 },
  193.5: { lambda: -2.126975375, mu: 20.49383457, sigma: 0.1489631101 },
  194.5: { lambda: -2.134873266, mu: 20.5339164, sigma: 0.1488369306 },
  195.5: { lambda: -2.142840157, mu: 20.573493869, sigma: 0.1487119885 },
  196.5: { lambda: -2.150865204, mu: 20.612559285, sigma: 0.1485887569 },
  197.5: { lambda: -2.158937201, mu: 20.651105058, sigma: 0.1484677151 },
  198.5: { lambda: -2.167044578, mu: 20.689123698, sigma: 0.1483493484 },
  199.5: { lambda: -2.175176987, mu: 20.726607282, sigma: 0.1482341202 },
  200.5: { lambda: -2.183317362, mu: 20.763550105, sigma: 0.148122614 },
  201.5: { lambda: -2.191457792, mu: 20.799943374, sigma: 0.1480152488 },
  202.5: { lambda: -2.199583649, mu: 20.83578051, sigma: 0.1479125643 },
  203.5: { lambda: -2.207681525, mu: 20.871054493, sigma: 0.1478150781 },
  204.5: { lambda: -2.215737645, mu: 20.905758394, sigma: 0.1477233147 },
  205.5: { lambda: -2.223739902, mu: 20.939884769, sigma: 0.1476377678 },
  206.5: { lambda: -2.231667995, mu: 20.973428578, sigma: 0.1475590832 },
  207.5: { lambda: -2.239511942, mu: 21.006381705, sigma: 0.1474877162 },
  208.5: { lambda: -2.247257081, mu: 21.038737402, sigma: 0.1474242097 },
  209.5: { lambda: -2.254885145, mu: 21.070489959, sigma: 0.1473691743 },
  210.5: { lambda: -2.26238209, mu: 21.101632407, sigma: 0.147323144 },
  211.5: { lambda: -2.269731517, mu: 21.132158447, sigma: 0.1472866982 },
  212.5: { lambda: -2.276917229, mu: 21.162061708, sigma: 0.1472604146 },
  213.5: { lambda: -2.283925442, mu: 21.191335097, sigma: 0.1472448281 },
  214.5: { lambda: -2.290731442, mu: 21.219974716, sigma: 0.1472406828 },
  215.5: { lambda: -2.29732427, mu: 21.247972623, sigma: 0.147248467 },
  216.5: { lambda: -2.303687802, mu: 21.275322389, sigma: 0.1472687698 },
  217.5: { lambda: -2.309799971, mu: 21.302019325, sigma: 0.1473022986 },
  218.5: { lambda: -2.315651874, mu: 21.328054894, sigma: 0.1473495144 },
  219.5: { lambda: -2.32121731, mu: 21.353425629, sigma: 0.1474112153 },
  220.5: { lambda: -2.326481911, mu: 21.378124616, sigma: 0.1474879793 },
  221.5: { lambda: -2.331428139, mu: 21.402145892, sigma: 0.1475804525 },
  222.5: { lambda: -2.336038473, mu: 21.425483514, sigma: 0.1476892889 },
  223.5: { lambda: -2.34029545, mu: 21.448131558, sigma: 0.1478151501 },
  224.5: { lambda: -2.344181703, mu: 21.470084116, sigma: 0.1479587057 },
  225.5: { lambda: -2.34768, mu: 21.491335286, sigma: 0.1481206332 },
  226.5: { lambda: -2.350773286, mu: 21.511879176, sigma: 0.1483016185 },
  227.5: { lambda: -2.353444725, mu: 21.531709894, sigma: 0.1485023554 },
  228.5: { lambda: -2.355677743, mu: 21.550821547, sigma: 0.1487235462 },
  229.5: { lambda: -2.35745607, mu: 21.569208237, sigma: 0.1489659018 },
  230.5: { lambda: -2.358763788, mu: 21.586864057, sigma: 0.1492301415 },
  231.5: { lambda: -2.359585369, mu: 21.603783087, sigma: 0.1495169936 },
  232.5: { lambda: -2.359905726, mu: 21.619959388, sigma: 0.1498271951 },
  233.5: { lambda: -2.359710258, mu: 21.635387002, sigma: 0.1501614923 },
  234.5: { lambda: -2.358980464, mu: 21.650061262, sigma: 0.150520734 },
  235.5: { lambda: -2.357714508, mu: 21.663972695, sigma: 0.1509054394 },
  236.5: { lambda: -2.355892424, mu: 21.677117355, sigma: 0.1513165313 },
  237.5: { lambda: -2.353501353, mu: 21.689489352, sigma: 0.1517548077 },
  238.5: { lambda: -2.350528726, mu: 21.701082884, sigma: 0.1522210861 },
  239.5: { lambda: -2.346962247, mu: 21.711892252, sigma: 0.1527162055 },
  240: { lambda: -2.34495843, mu: 21.716999342, sigma: 0.152974718 },
  240.5: { lambda: -2.342796948, mu: 21.721909734, sigma: 0.1532408716 },
};

export const getChildBMIPercentile = (
  gender: string,
  ageInMonths: number,
  bmi: number,
) => {
  const childBMILMSDataTable =
    gender === 'Female' ? femaleChildBMILMSParams : maleChildBMILMSParams;

  return zScoreFromMeasurement(bmi, ageInMonths, childBMILMSDataTable);
};
