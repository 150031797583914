import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Directive,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { get } from 'lodash';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { debounceTime, map, startWith, take, takeUntil } from 'rxjs/operators';

import { PatientSelectors } from '@app/core';
import {
  ageInHalfMonths,
  isInfant,
  isMinor,
} from '@app/core/patient/shared/patient-utils';
import { Patient } from '@app/core/patient/shared/patient.type';
import { ReferenceDataKeys } from '@app/modules/reference-data/shared/reference-data.type';
import { ReferenceDataSelectors } from '@app/modules/reference-data/store/reference-data.selectors';
import { vitalTypes } from '@app/modules/vitals-data/shared/vitals-data.type';
import { getBmi } from '@app/modules/vitals-data/shared/vitals-utils';
import { camelCase } from '@app/utils';
import { ordinalizePercentile } from '@app/utils';

import { MeasurementValidationRules } from '../shared/measurement-validation-rules.type';
import {
  childFieldToConfigMapping,
  infantFieldToConfigMapping,
} from '../shared/percentile-calculator';

@Component({
  selector: 'omg-vital-set-form',
  templateUrl: './vital-set-form.component.html',
  styleUrls: ['./vital-set-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VitalSetFormComponent implements OnInit, OnDestroy {
  @Input() form: FormGroup;
  @Input() patient: Patient;

  gender: string;
  ageInHalfMonths: number;
  isMinor: boolean;
  isInfant: boolean;
  weight: any;

  unsubscribe: Subject<void> = new Subject();
  measurementValidationRules$: Observable<MeasurementValidationRules>;

  weight$: Observable<number>;
  height$: Observable<number>;
  headCircumference$: Observable<number>;
  bodyMassIndex$: Observable<number>;

  weightPercentile$: Observable<number>;
  heightPercentile$: Observable<number>;
  headCircumferencePercentile$: Observable<number>;
  bmiPercentile$: Observable<number>;

  ordinalizedWeightPercentile$: Observable<string>;
  ordinalizedHeightPercentile$: Observable<string>;
  ordinalizedHeadCircumferencePercentile$: Observable<string>;
  ordinalizedBmiPercentile$: Observable<string>;

  constructor(private referenceDataSelectors: ReferenceDataSelectors) {}

  ngOnInit(): void {
    this.measurementValidationRules$ = this.referenceDataSelectors.get<
      MeasurementValidationRules
    >(ReferenceDataKeys.measurementValidationRules);

    this.isInfant = isInfant(this.patient);
    this.isMinor = isMinor(this.patient);
    this.ageInHalfMonths = ageInHalfMonths(this.patient);
    this.gender = this.patient.gender;

    if (this.isInfant) {
      this.weight = {
        key: vitalTypes.infantWeight,
        unit: 'kg',
        label: 'infant wt',
      };
    } else {
      this.weight = { key: vitalTypes.weight, unit: 'lb', label: 'wt' };
    }

    this.setupForm();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  get growthFieldConfig() {
    if (this.isInfant) {
      return infantFieldToConfigMapping;
    } else if (this.isMinor) {
      return childFieldToConfigMapping;
    } else {
      return {};
    }
  }

  private setupForm(): void {
    this.weight$ = this.form
      .get(this.weight.key)
      .valueChanges.pipe(startWith(this.form.value[this.weight.key]));
    this.height$ = this.form
      .get(vitalTypes.height)
      .valueChanges.pipe(startWith(this.form.value[vitalTypes.height]));
    this.headCircumference$ = this.form
      .get(vitalTypes.headCircumference)
      .valueChanges.pipe(
        startWith(this.form.value[vitalTypes.headCircumference]),
      );

    [
      this.weightPercentile$,
      this.ordinalizedWeightPercentile$,
    ] = this.percentileStream$(this.weight$, vitalTypes.weight);

    [
      this.heightPercentile$,
      this.ordinalizedHeightPercentile$,
    ] = this.percentileStream$(this.height$, vitalTypes.height);

    [
      this.headCircumferencePercentile$,
      this.ordinalizedHeadCircumferencePercentile$,
    ] = this.percentileStream$(
      this.headCircumference$,
      vitalTypes.headCircumference,
    );

    this.bodyMassIndex$ = combineLatest([this.weight$, this.height$]).pipe(
      takeUntil(this.unsubscribe),
      map(([weight, height]) => {
        if (this.isInfant) {
          return null;
        } else {
          const bmi = Number(getBmi(weight, height).toFixed(1));
          return bmi > 0 && Number.isFinite(bmi) ? bmi : null;
        }
      }),
    );

    this.bodyMassIndex$.subscribe(bmi => {
      this.form.get(vitalTypes.bodyMassIndex).setValue(bmi, {
        emitEvent: false,
        emitModelToViewChange: true,
      });
    });

    [
      this.bmiPercentile$,
      this.ordinalizedBmiPercentile$,
    ] = this.percentileStream$(this.bodyMassIndex$, vitalTypes.bodyMassIndex);
  }

  private percentileStream$(
    vital$: Observable<number>,
    vitalType: string,
  ): [Observable<number>, Observable<string>] {
    const { percentileFn, percentileVitalType } =
      this.growthFieldConfig[vitalType] || {};

    if (!percentileFn || !percentileVitalType) {
      return [of(null), of(null)];
    }

    const percentile$ = vital$.pipe(
      map(value =>
        value ? percentileFn(this.gender, this.ageInHalfMonths, value) : null,
      ),
    );

    percentile$.subscribe(value =>
      this.form.controls[percentileVitalType].setValue(value),
    );

    const ordinalizedPercentile$ = percentile$.pipe(
      map(value => ordinalizePercentile(value)),
    );

    return [percentile$, ordinalizedPercentile$];
  }
}
