import { Component, Input } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

import { InternalUserInfoService } from '@app/shared/components/internal-user-info/internal-user-info.service';

import { Size } from '../profile-image-bubble/profile-image-bubble.component';
import { InternalUser, Role } from './internal-user.type';

enum RoleIds {
  provider = 1,
  vmt = 12,
  phleb = 13,
}

function containsVMT(roles: Role[]): boolean {
  return roles.findIndex(role => role.id === RoleIds.vmt) > -1;
}

@Component({
  selector: 'omg-internal-user-info-popover',
  templateUrl: './internal-user-info-popover.component.html',
  styleUrls: ['./internal-user-info-popover.component.scss'],
})
export class InternalUserInfoPopoverComponent {
  @Input() set providerId(id: number) {
    this.setScheduleUrl(id);
    this.userInfo$ = this.internalUserInfoService.get(id + '').pipe(
      filter(user => !!user),
      tap(user => {
        this.setDirectoryUrl(
          user.firstName,
          user.lastName,
          user.serviceArea.name,
        );
        this.setRole(user.preferredRole, user.nonPreferredRoles);
      }),
    );
  }

  constructor(private internalUserInfoService: InternalUserInfoService) {}

  readonly large = Size.large;
  userInfo$: Observable<InternalUser>;
  expanded = false;
  directoryUrl: string;
  scheduleUrl: string;
  roleName: string;

  toggleChevron() {
    this.expanded = !this.expanded;
  }

  private setDirectoryUrl(
    firstName: string,
    lastName: string,
    serviceAreaName: string,
  ) {
    this.directoryUrl = `${environment.adminApp.host}/admin/provider-directory/search?q=${firstName} ${lastName}&loc=${serviceAreaName}`;
  }

  private setScheduleUrl(providerId: number) {
    this.scheduleUrl = `${environment.adminApp.host}/schedule/provider-schedule/provider/${providerId}`;
  }

  private setRole(primaryRole: Role, secondaryRoles: Role[]) {
    if (primaryRole.id === RoleIds.provider && containsVMT(secondaryRoles)) {
      this.roleName = 'VMT';
    } else if (primaryRole.id === RoleIds.phleb) {
      this.roleName = 'Phleb';
    } else {
      this.roleName = primaryRole.name;
    }
  }
}
